import {BikiniSection} from '../types/bikini'

import React, {useState, useEffect} from 'react'
import {graphql} from 'gatsby'
import styled from 'styled-components'

import Layout from '../layout'
import SectionsTemplate from '../templates/sections'

import {contentImages} from '../helpers/text'

const CashlessIframe = styled.iframe`
  width: 100%;
  border: none;
  background-color: transparent;
`

const Account: React.FC = () => {
  const [height, setHeight] = useState(24)

  useEffect(() => {
    const onMessage = (message: MessageEvent) => {
      if (message.data) {
        let receivedHeight: number
        if (typeof message.data === 'string') {
          receivedHeight = Number(message.data.split('[WeezeventWidget] ')[1])
        } else if (message.data.action === 'update-size') {
          receivedHeight = Number(message.data.height)
        }
        if (receivedHeight) {
          // not NaN & not zero & not undefined
          setHeight(receivedHeight)
        }
      }
    }

    window.addEventListener('message', onMessage)
    return () => {
      window.removeEventListener('message', onMessage)
    }
  })

  return (
    <CashlessIframe
      src="https://widget.nemopay.net/#/?color_btnbg=040000&color_btnhbg=3e3d3d&system_id=213829&event=1&lang=fr"
      scrolling="no"
      frameBorder={0}
      style={{height: `${height}px`}}
    />
  )
}

const extras = {
  account: Account,
}

const CashlessPage = ({data}) => {
  const sections: BikiniSection[] = data.allBikiniSection.edges.map(contentImages)

  return (
    <Layout title="Infos pratiques">
      <SectionsTemplate sections={sections} extras={extras} />
    </Layout>
  )
}

export default CashlessPage

export const query = graphql`
  query {
    allBikiniSection(filter: {page: {eq: "cashless"}}, sort: {fields: [order], order: ASC}) {
      edges {
        node {
          slug
          title
          content
          childrenFile {
            childImageSharp {
              fluid(maxWidth: 1320) {
                # TODO: only create one version
                src
              }
            }
            url
          }
        }
      }
    }
  }
`
